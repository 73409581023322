import dayjs from "dayjs";
import { t } from "i18next";
import React, { useEffect, useState } from "react";
import { Modal } from "react-native-web";
import { useHistory, useParams } from "react-router-dom";
import PickerInput from "../../components/UI/AddingData/PickerInput";
import { handleLoading } from "../../store/actions/loadingAction";

import { DateInput, TextInput } from "./CreateUserScreen";
import SelectUserModal from "../Supplements/Sell/SelectUserModal";
import ArrayIndex from "../../components/UI/AddingData/ArrayIndex";
import useSwr from 'swr'
import { Error, Loading, fetcher } from "../UI/CentersViewData";
import axios from "axios";
import CommonInputsContainer from "../../components/UI/TableView/CommonInputsContainer";
import InstanceIndex from "../../components/UI/AddingData/InstanceIndex";
import i18n from "../../i18n";
import { IoTrashOutline } from "react-icons/io5";
const smalltalk = require("smalltalk");

function MembershipDetailScreen() {
  const [currentMembership, setCurrentMembership] = useState(false);
  const membershipId = useParams().membershipId
  const [toggleUserModal, setToggleUserModal] = useState(false);
  const { data: packages, error: pError, isLoading: lError } = useSwr(`/packages`, fetcher)
  const language = i18n.language
  const { data, isLoading, error } = useSwr('/memberships/get/' + membershipId, fetcher)

  const history = useHistory();

  const onChangeValue = (type, value) => {
    switch (type) {
      case 0: {
        currentMembership.date = dayjs(value).format("YYYY-MM-DD");
        break;
      }
      case 1: {
        currentMembership.package.price.value = value;
        break;
      }
      case 2: {
        // console.log(convertCurrency(value.price.value,"IQD"))
        const pd = currentMembership.package.paymentDetails
        currentMembership.package = { ...value, paymentDetails: { ...pd, amountByCustomer: 0 }, price: { ...value.price, iqd: 0 } };
        setToggleUserModal(false);
        break;
      }
      case 3: {
        currentMembership.userId = value;
        break;
      }
      case 4: {
        currentMembership.centerId = value;
        break;
      }
      case 5: {
        currentMembership.package.price.iqd = Number(value);
        break;
      }
      case 6: {
        currentMembership.adminId = (value);
        break;
      }
      case 7: {
        currentMembership.package.paymentDetails.payedType = Number(value);
        break;
      }
      case 8: {
        currentMembership.package.paymentDetails.amountByCustomer = Number(value);
        break;
      }
      case 9: {
        currentMembership.package.paymentDetails.dollarAmount = Number(value);
        break;
      }
      case 10: {
        currentMembership.package.paymentDetails.iqdAmount = Number(value);
        break;
      }
      case 11: {
        currentMembership.package.paymentDetails.changeType = Number(value);
        break;
      }
      case 12: {
        currentMembership.package.paymentDetails.changePrice = Number(value);
        break;
      }
      default:
        break;
    }

    setCurrentMembership({ ...currentMembership });
  };

  const onSubmit = async () => {
    try {
      let newObj = {
        ...currentMembership,
        date: dayjs(currentMembership.date).format("YYYY-MM-DD"),
      };
      await checkValidation(newObj);
      // change memberships
      handleLoading();

      await axios.put(`/memberships/change-membership/${newObj._id}`, newObj).catch(e => {
        handleLoading();
        throw new Error(e.response.data.message)
      });

      handleLoading();
      history.goBack();
    } catch (e) {
      console.log(e.message)
      // handleLoading();
      alert(e || e.message);
    }
  };

  const checkValidation = async (newObj) => {
    if (!newObj.date) {
      throw ("Please enter date");
    }
    if (!newObj.package) {
      throw ("Please enter package");
    }
    if (newObj.package.price.value !== 0 && (isNaN(newObj.package.price.iqd) || isNaN(newObj.package.paymentDetails.amountByCustomer))) {
      throw ("Please enter iqd price and amount by customer");
    }
    if (isNaN(newObj.package.price.value)) {
      throw ("Please enter price");
    }
    if (isNaN(newObj.package.price.iqd)) {
      throw ("Please enter price in iqd");
    }
    if (!newObj.package.paymentDetails) {
      throw ("Please enter payment details");
    }
    if (isNaN(newObj.package.paymentDetails.payedType)) {
      throw ("Please enter payed type");
    }
    if (newObj.package.paymentDetails.payedType === 2) {
      if (isNaN(newObj.package.paymentDetails.dollarAmount)) {
        throw ("Please enter dollar amount");
      }
      if (isNaN(newObj.package.paymentDetails.iqdAmount)) {
        throw ("Please enter iqd amount");
      }
    } else {
      if (isNaN(newObj.package.paymentDetails.amountByCustomer)) {
        throw ("Please enter amount by customer");
      }
    }
    if (isNaN(newObj.package.paymentDetails.changeType)) {
      throw ("Please enter change type");
    }
    if (newObj.package.paymentDetails.changeType !== 2) {
      if (isNaN(newObj.package.paymentDetails.changePrice)) {
        throw ("Please enter change price");
      }
    }

  }

  useEffect(() => {
    if (data) {
      setCurrentMembership(data);
    }
  }, [data])

  const toggleUserModalHandler = () => {
    setToggleUserModal(!toggleUserModal);
  };

  const onDeleteMembership = async () => {
    try {
      await smalltalk
        .confirm(
          t("areYouSure"),
          t("deleteMembershipWarning"),
          {
            labels: {
              ok: t("yes"),
              cancel: t("no"),
            },
          }
        )
        .then(async (value) => {
          handleLoading();
          await axios.delete(`/memberships/${currentMembership._id}`).then(() => {
            handleLoading();
            if (currentMembership.type === 0) {
              history.replace(`/centers/62d3de07e4b5f62ba339b22b`)
            } else {
              history.goBack();
            }
          }).catch(e => {
            handleLoading();
            alert(e.response.data.message)
            // throw new Error(e.response.data.message)
          });

        });
    } catch (e) {
      console.log(e)
      // alert(e);
    }
  }
  console.log({ error })
  if (isLoading || lError) return <Loading />
  if (error) return <Error />

  return (
    <div className="flex flex-1 w-full">
      <CommonInputsContainer customIcon={<IoTrashOutline color="red" onClick={onDeleteMembership} size={30} />} warning={t("pleaseBeCarefulOfChangingMembership")} onSave={onSubmit} title={t('membership')} >
        <div style={{
          textAlign: language === 'en' ? "left" : "right"
        }} className="p-6 text-sm" >
          <p>{t("notes")}</p>
          <p>{t("changeMembershipNotes_1")}</p>
          <p>{t("changeMembershipNotes_2")}</p>
        </div>
        {currentMembership._id && (
          <InstanceIndex label={t("id")} value={`#${currentMembership._id}`} />
        )}
        <DateInput
          label={t("startDate")}
          placeHolder={t("startDate")}
          value={currentMembership?.date}
          onChangeValue={onChangeValue}
          type={0}
        />
        <TextInput
          label={t("price")}
          placeHolder={t("price") + '$'}
          value={currentMembership?.package?.price?.value}
          onChangeValue={onChangeValue}
          type={1}
        />
        <TextInput
          label={t("price")}
          placeHolder={t("price") + ' IQD '}
          value={currentMembership?.package?.price?.iqd}
          onChangeValue={onChangeValue}
          type={5}
        />
        {currentMembership.package?.paymentDetails && (
          <>
            <ArrayIndex
              label={t("payedType")}
              data={[{ _id: 0, label: 'dollar' }, { _id: 1, label: 'iqd' }, { _id: 2, label: 'dollar + iqd' }]}
              type={7}
              valType={1}
              value={currentMembership.package?.paymentDetails?.payedType}
              index='_id'
              onChangeValue={onChangeValue}
            />
            {currentMembership.package?.paymentDetails?.payedType === 2 ? <>
              <TextInput
                label={t("dollarAmount")}
                placeHolder={t("dollarAmount")}
                value={currentMembership.package?.paymentDetails?.dollarAmount}
                onChangeValue={onChangeValue}
                type={9}
              />
              <TextInput
                label={t("iqdAmount")}
                placeHolder={t("iqdAmount")}
                value={currentMembership.package.paymentDetails?.iqdAmount}
                onChangeValue={onChangeValue}
                type={10}
              />
            </> : <TextInput
              label={t("amountByCustomer")}
              placeHolder={t("amountByCustomer")}
              value={currentMembership.package?.paymentDetails?.amountByCustomer}
              onChangeValue={onChangeValue}
              type={8}
            />}
            <ArrayIndex
              label={t("changeType")}
              data={[{ _id: 0, label: 'dollar' }, { _id: 1, label: 'iqd' }, { _id: 2, label: 'dollar + iqd' }]}
              type={11}
              valType={1}
              value={currentMembership.package?.paymentDetails?.changeType}
              index='_id'
              onChangeValue={onChangeValue}
            />
            {currentMembership.package?.paymentDetails?.payedType === 2 ? <></> : <TextInput
              label={t("changePrice")}
              placeHolder={t("changePrice")}
              value={currentMembership.package?.paymentDetails?.changePrice}
              onChangeValue={onChangeValue}
              type={12}
            />}
          </>
        )}
        <PickerInput
          label={t("package")}
          value={currentMembership?.package}
          onPress={toggleUserModalHandler}
          isPackage
        />
        <Modal visible={toggleUserModal} transparent>
          <SelectUserModal
            toggleUserModalHandler={toggleUserModalHandler}
            currentUser={currentMembership?.package}
            customers={packages}
            isPackage
            onChangeCurrentUser={onChangeValue.bind(this, 2)}
          />
        </Modal>
      </CommonInputsContainer>
    </div>
  );
}

export default MembershipDetailScreen;
